.header {
  height: 3.5rem;
}

.dashboard {
  height: calc(100vh - 3.5rem);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: end;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
  width: 50rem;
  border-radius: 6px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
  cursor: pointer;
}

.p-button {
  border-radius: 6px;
}

.p-dataview-content {
  border-radius: 6px;
  padding: .5rem;
}

.schedule-input {
  width: 7rem;
  display: inline-block;
}